export default {
  menu: [
    {
      id: 'home',
      label: 'Inicio',
      path: '/app/home',
      icon: '$vuetify.icons.starling_home',
      desktop: true,
      mobile: true,
    },
    {
      id: 'training',
      label: 'Capacitación',
      path: '/app/training',
      icon: '$vuetify.icons.starling_therapy',
      desktop: true,
      mobile: true,
    },
    {
      id: 'exercises',
      label: 'Ejercicios',
      path: '/app/exercises',
      icon: '$vuetify.icons.starling_exercises',
      desktop: true,
      mobile: true,
    },
    {
      id: 'videos',
      label: 'Videos',
      path: '/app/videos',
      icon: '$vuetify.icons.starling_videos',
      desktop: true,
      mobile: true,
    },
    {
      id: 'tools',
      label: 'Herramientas',
      path: '/app/tools',
      icon: '$vuetify.icons.starling_tools',
      desktop: true,
      mobile: true,
    },
    {
      id: 'community',
      label: 'Comunidad',
      path: '/app/community',
      icon: '$vuetify.icons.starling_community',
      desktop: true,
      mobile: true,
    },
  ],
  more_button: 'Más',
  favorites_button: 'Favoritos',
  profile_button: 'Perfil',
};
