import { Module } from 'vuex';
import { RootState } from '../../types';
import { WebinarRegistrant, WebinarState } from './types';

export const defaultState: WebinarState = {
};

export const webinar: Module<WebinarState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
  },
  actions: {
    getAvailableWebinars({ commit, dispatch }) {
      const callback = (res: WebinarRegistrant[]) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/webinar`, callback ], { root: true });
    },
    checkRegistrationWebinar({ commit, dispatch }, webinarId: string) {
      const callback = (res: WebinarRegistrant) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/webinar/${webinarId}`, callback ], { root: true });
    },
    registerWebinar({ commit, dispatch }, webinarId: string) {
      const callback = (res: WebinarRegistrant) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/webinar/${webinarId}`, callback, 'post' ], { root: true });
    },
    unregisterWebinar({ commit, dispatch }, webinarId: string) {
      return dispatch('$apiRequest', [ `/webinar/${webinarId}`, null, 'delete' ], { root: true });
    },
  },
  getters: {
  },
};
