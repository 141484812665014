<template>
<svg class="profile-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
  <path d="M7.773 7.91c0-4.085 3.325-7.41 7.41-7.41 4.086 0 7.41 3.325 7.41 7.41 0 4.086-3.324 7.41-7.41 7.41-4.085 0-7.41-3.324-7.41-7.41Zm.758 0a6.66 6.66 0 0 0 6.653 6.652 6.66 6.66 0 0 0 6.652-6.652 6.66 6.66 0 0 0-6.652-6.652A6.66 6.66 0 0 0 8.53 7.91Z"/>
  <path d="M5.698 21.339c2.073-2.105 4.816-3.26 7.728-3.26h3.515c2.912 0 5.655 1.155 7.728 3.26a11.028 11.028 0 0 1 3.198 7.782c0 .21-.17.379-.379.379H2.878a.379.379 0 0 1-.378-.379c0-2.925 1.135-5.687 3.198-7.782Zm-2.4 6.86-.048.543H27.117l-.048-.544c-.463-5.236-4.823-9.362-10.128-9.362h-3.515c-5.305 0-9.665 4.126-10.128 9.362Z"/>
  <path class="icon-content" d="M7.773 7.91c0-4.085 3.325-7.41 7.41-7.41 4.086 0 7.41 3.325 7.41 7.41 0 4.086-3.324 7.41-7.41 7.41-4.085 0-7.41-3.324-7.41-7.41Z"/>
  <path class="icon-content" d="M5.698 21.339c2.073-2.105 4.816-3.26 7.728-3.26h3.515c2.912 0 5.655 1.155 7.728 3.26a11.028 11.028 0 0 1 3.198 7.782c0 .21-.17.379-.379.379H2.878a.379.379 0 0 1-.378-.379c0-2.925 1.135-5.687 3.198-7.782Z"/>
</svg>
</template>
<script>
export default {
  name: 'profile-icon',
};
</script>

<style scoped>
.profile-icon {
  fill: transparent;
  stroke: currentColor;
}

.profile-icon .icon-content {
  fill: transparent;
  stroke: transparent;
}

.profile-icon.activated .icon-content,
.router-link-active .v-icon.profile-icon .icon-content,
.v-btn--active .v-icon.profile-icon .icon-content {
  fill: currentColor;
  stroke: transparent;
}
</style>
