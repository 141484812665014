export default {
  header_title: '',
  introduction: {
    action: '¡Entiendo!',
  },
  subheader: {
    welcome: 'Te damos la bienvenida {name}!',
    welcome_back: '¡Nos alegra verte de nuevo {name}!',
    progress_title: 'Monitorear tu progreso',
    recommendation_title: 'Recomendaciones:',
    recommendation_subtitle: 'Para mejorar tu salud mental, haz una, dos o las tres cosas.',
    app_store: {
      desktop: 'Buscar Starling Minds en App Store',
      mobile: 'Soporte en cualquier lugar',
      button: 'Abrir app',
      ios_link: 'https://apps.apple.com/us/app/starling-salud-mental/id6467989915?l=es-MX',
      android_link: 'https://play.google.com/store/apps/details?id=com.starlingminds.member&hl=es&gl=CA',
    },
  },
  recommendations: {
    track_progress: {
      checkup_due: {
        title: 'Chequeo',
        description: 'Seguir el progreso',
        checkup_time: '~15 mins',
      },
      checkup_completed: {
        title: '¡Chequeo completado!',
        next_checkup: 'Siguiente Chequeo:',
        congratulations: '¡Bien hecho! Monitorearemos tu progreso en {weeks} semanas.',
      },
    },
    build_skills: {
      checkup_first: {
        title: 'Haz un Chequeo para generar tu recomendación de <strong>Capacitación</strong>.',
        training_title: 'Biblioteca de recursos de capacitación',
        training_subtitle: 'Encontrarás sesiones personalizadas de capacitación de 10 a 20 minutos de duración.',
      },
      all_complete: {
        title: 'Biblioteca de recursos de capacitación',
        subtitle: 'Revisa las sesiones y los conceptos clave.',
        step_completed: {
          title: '¡Revisión de capacitación completada!',
          congratulations: '¡Lo lograste! Sigue utilizando las herramientas y estrategias para reforzar tus habilidades.',
        },
      },
      recommended: {
        title: 'Capacitación en salud mental',
      },
    },
    connect_others: {
      community_forums: {
        title: 'Conectarse y compartir',
        description: 'Comunidad',
        time: '~5 mins',
        title_completed: '¡Conexión a la comunidad completada!',
        congratulations: '¡Gracias por formar parte de la comunidad!',
      },
      strategic_breathing: {
        title: 'Respiración metódica',
        subtitle: 'Tómate un momento para respirar y relajarte antes de explorar Starling.',
        description: 'Video',
        time: '~2 mins',
        title_completed: '¡Sesión Respiración metódica completada!',
        congratulations: '¡Bien hecho! Puedes consultar esta estrategia en cualquier momento en la sección de <strong>Videos</strong>.',
      },
      guided_imagery: {
        title: 'Imaginería guiada',
        subtitle: 'Este breve ejercicio te ayudará a relajarte al hacerte imaginar una escena tranquilizadora.',
        description: 'Video',
        time: '~3 mins',
        title_completed: '¡Sesión Relajación guiada completada!',
        congratulations: '¡Bien hecho! Puedes consultar esta estrategia en cualquier momento en la sección de <strong>Videos</strong>.',
      },
      mindfulness: {
        title: 'La pleine conscience',
        subtitle: 'Coge un caramelo de menta, una nuez o un pedazo de fruta, y utiliza este ejercicio para enfocar las cosas.',
        description: 'Video',
        time: '~3 mins',
        title_completed: '¡Sesión Consciencia plena completada!',
        congratulations: '¡Bien hecho! Puedes consultar esta estrategia en cualquier momento en la sección de <strong>Videos</strong>.',
      },
    },
    practice: {
      checkup_first: {
        title: 'Haz un Chequeo para generar tu recomendación de <strong>Ejercicio</strong>.',
      },
      all_complete: {
        title: 'Ejercicios',
        subtitle: 'Revisa los ejercicios clave.',
        step_completed: {
          title: '¡Revisión de ejercicios completada!',
          congratulations: 'Vuelve a los ejercicios en cualquier momento para practicar tus habilidades.',
        },
      },
    },
    buttons: {
      explore: 'Explorar',
      more: 'Más',
      profile: 'Perfil',
    },
  },
  memberIdModal: {
    title: 'Introducir tu identificación de miembro',
    description: 'te pide que añadas tu identificación de miembro en tu perfil para verificar que formas parte de la organización. Tus datos y resultados permanecerán anónimos.',
    contact_text: 'Ponte en contacto con tu organización para localizar tu identificación de miembro.',
    button: 'Guardar',
  },
  pickup_image: {
    normal: 'pickup_normal.svg',
    active: 'pickup_active.svg',
  },
  learn_about: {
    title: 'Aprende sobre Starling.',
  },
  next_step: {
    title: 'Siguiente paso para ti',
    lets_go: '¡Empecemos!',
  },
  user_stats_card: {
    subtitle: 'Tus estadísticas',
    widgets: {
      battery: {
        value: '{value}/10',
        text: 'Energía',
        additional: '3 días o más',
      },
      anxiety_meter: {
        text: 'Ansiedad',
      },
      resilience_meter: {
        text: 'Resiliencia',
      },
      depression_meter: {
        text: 'Depresión',
      },
      checkup_reminder: {
        empty: 'Siguiente Chequeo recomendado en <strong class="checkup-value">___</strong> días',
        past: 'Tu próximo Chequeo recomendado está <strong class="checkup-value">pendiente</strong>',
        future: 'Siguiente Chequeo recomendado en <strong class="checkup-value">{count}</strong> días',
      },
    },
    incentive: 'Tus estadísticas se mostrarán aquí una vez que hayas completado tu primer Chequeo.',
    buttons: {
      first_check_up: 'Hacer tu primer Chequeo',
      check_up: 'Hacer un Chequeo',
      check_up_results: 'Ver mi progreso',
    },
    explanations: {
      title: 'El significado de tus resultados',
      global: 'Estas estadísticas se han extraído de tu último Chequeo.',
      battery: '<strong>Batería</strong> = el nivel de energía que reportaste ese día.',
      anxiety_depression: '<strong>Puntuaciones de ansiedad y depresión</strong> = una norma científica, que se categoriza como:',
      anxiety_depression_levels: [
        { interval: '0 - 2', name: 'Saludable' },
        { interval: '3 - 4', name: 'Límite' },
        { interval: '5 - 9', name: 'Leve' },
        { interval: '10 - 14', name: 'Moderado' },
        { interval: '> 14', name: 'Grave' },
      ],
      resilience: '<strong>Resiliencia</strong> = el nivel de resiliencia que reportaste ese día, y que se categoriza como:',
      resilience_levels: [
        { interval: '6 - 8', name: 'Alto' },
        { interval: '3 - 5', name: 'Moderado' },
        { interval: '0 - 2', name: 'Bajo' },
      ],
      incentive: 'Monitorea tu progreso realizando el Chequeo regularmente.',
    },
  },
  training_courses: {
    title: 'Terapias guiadas',
  },
  community_card: {
    title: 'Foros de la comunidad de Starling',
    subtitle: 'Un espacio seguro para compartir tus experiencias, aprender de personas que atraviesan situaciones similares y encontrar apoyo.',
  },
  video_library_card: {
    title: 'Videoteca',
    subtitle: 'Prueba una estrategia, aprende más sobre el autocuidado para la salud mental o profundiza en la ciencia que hay detrás de la TCC.',
  },
  referral_acceptance: {
    title: 'Actualizar tus datos',
    subtitle: 'Actualizar tus datos',
    subheader: {
      content: [
        'Estamos aquí para apoyarte con tu salud mental y centrarnos en ti durante tu licencia laboral. Descubre tus patrones, desarrolla estrategias y aumenta tu confianza para regresar al trabajo.',
        'Antes de empezar, confirmemos algunos de tus datos.',
      ],
    },
    profile_title: 'Primero, actualicemos tu perfil.',
    cancel_button: 'Cancelar',
    message: {
      title: '¡Te han invitado a Volver a la Salud!',
      content: '<i>{caseManager}</i> cree que el programa Return-to-Health podría resultarle útil:',
      cta: '¡Comience a explorar hoy!',
      buttons: {
        accept: 'Aceptar la invitacion',
        decline: 'Rechazar invitación',
      },
    },
    update_profile: {
      title: 'Actualizar tus datos',
      buttons: {
        accept: '¡Empecemos!',
        back: 'Volver',
      },
    },
  },
};
