<template>
  <v-app id="app" class="app-main"
    v-touch="{ right: swipeLeftRight }">
    <starling-loading v-if="isLoadingProgress"></starling-loading>
    <template v-else>
      <router-view></router-view>

      <template v-if="enableThemeEditor">
        <primary-button
            small
            color="primary--gradient"
            class="white--text font-weight-bold"
            fab
            fixed
            top
            right
            @click="openThemeEditor = true">
          <v-icon>mdi-palette</v-icon>
        </primary-button>
        <theme-editor v-model="openThemeEditor"></theme-editor>
      </template>

    </template>
  </v-app>
</template>

<script>
import ThemeEditor from '@/views/theme/theme-editor';
import { mapGetters, mapState } from 'vuex';
import PrimaryButton from '@/views/components/button/primary-button';
import { Auth } from 'aws-amplify';
import StarlingLoading from './views/components/loader/starling-loading.vue';
import { SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY } from '@/views/landing/setup_profile/SetupAccessCode';

export default {
  name: 'app',
  components: {
    PrimaryButton,
    ThemeEditor,
    StarlingLoading,
  },
  data() {
    return {
      intervalId: null,
      openThemeEditor: false,
    };
  },
  computed: {
    enableThemeEditor() {
      return this.testEnvironment && this.$vuetify.breakpoint.lgAndUp;
    },
    ...mapState({
      partner: state => {
        return state.identity;
      },
      showBackButton: state => {
        return state.navigation.top.showBackButton;
      },
    }),
    ...mapGetters({
      platform: 'getPlatform',
      isNativePlatform: 'isNativePlatform',
      isLoadingProgress: 'isLoadingProgress',
    }),
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newVal) {
        this.$store.dispatch('identity/applyContent', { content: this.partner.content });
      },
    },
  },
  created() {
    const ping = () => {
      this.$store.dispatch('ping');
    };
    ping();
    this.intervalId = setInterval(ping, 60_000);
    this.capacitorDeepLink(this.handleMobileSignInOut);
  },
  mounted() {
    const loadingScreen = document.getElementById('loading-screen');
    if (loadingScreen) {
      loadingScreen.parentElement.removeChild(loadingScreen);
    }
  },
  updated() {
    this.issueCollector();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    handleMobileSignInOut(url) {
      if (url.includes('/sign_up')) {
        const urlRegex = /(?:starlingminds\.com)(?:\/#)?(.*?)(?:\?(.*?))?$/i;
        const urlMatch = url.match(urlRegex);
        const slug = (urlMatch && urlMatch.length > 1) ? url.match(urlRegex)[1] : null;
        const params = (urlMatch && urlMatch.length > 2) ? url.match(urlRegex)[2] : null;
        const urlParams = params ? new URLSearchParams(params) : null;
        this.$log.debug('Slug is ', slug, 'and params are ', params);
        if (urlParams && urlParams.has('ac_imprint')) {
          this.$log.debug('Navigating to sign_up path: ');
          localStorage.setItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY, urlParams.get('ac_imprint'));
        }
        this.$router.push({ name: 'sign_up' }).catch(error => {
          this.$log.debug('Error navigating to sign_up path', error);
          this.$router.push({ name: 'home' });
        });
        return;
      }

      if (!url.includes('/federated_end') && !url.includes('/federated_signout')) {
        const urlRegex = /(?:starlingminds\.com)(?:\/#)?(.*?)(?:\/)?$/i;
        const urlMatch = url.match(urlRegex);
        const slug = (urlMatch && urlMatch.length > 1) ? url.match(urlRegex)[1] : null;
        if (!slug) {
          this.$router.push({ name: 'home' });
        } else {
          this.$router.push({ path: slug }).catch(error => {
            this.$log.debug('Error navigating to path: ' + slug, error);
            this.$router.push({ name: 'home' });
          });
        }
        return;
      }

      (Auth)._handleAuthResponse(url);

      if (this.platform === 'ios') {
        this.capacitorBrowserClose().catch();
      }

      if (url.includes('/federated_end')) {
        this.$store.dispatch('identity/configureAuth').then(res => {
          this.$router.replace({ name: 'home' });
        });
      } else {
        this.$router.replace({ name: 'presentation' });
      }
    },
    swipeLeftRight(e) {
      if (!this.isNativePlatform || !this.showBackButton || e.offsetX < 100) return;
      if (e.touchstartX < 50) {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss">
@import './styles/app.scss';
</style>
