export default {
  header_title: '',
  introduction: {
    action: 'J\'ai compris',
  },
  subheader: {
    welcome: 'Bienvenue {name}!',
    welcome_back: 'Bienvenue à nouveau {name}!',
    progress_title: 'Suivez vos progrès',
    recommendation_title: 'Recommandations :',
    recommendation_subtitle: 'Pour améliorer votre santé mentale, faites-en un, deux ou les trois.',
    app_store: {
      desktop: 'Chercher Staling Minds dans App Store',
      mobile: 'Soutien en tout lieu',
      button: 'Ouvrir appli',
      ios_link: 'https://apps.apple.com/ca/app/starling-forme-mentale/id6467989915?l=fr-CA',
      android_link: 'https://play.google.com/store/apps/details?id=com.starlingminds.member&hl=fr&gl=CA',
    },
  },
  recommendations: {
    track_progress: {
      checkup_due: {
        title: 'Bilan de Santé',
        description: 'Suivre les progrès',
        checkup_time: '~15 mins',
      },
      checkup_completed: {
        title: 'Bilan terminé!',
        next_checkup: 'Prochain Bilan de Santé:',
        congratulations: 'Excellent travail! Nous suivrons vos progrès dans {weeks} semaines.',
      },
    },
    build_skills: {
      checkup_first: {
        title: 'Terminez un Bilan de Santé pour initialiser vos recommandations d\'<strong>Entraînement</strong>.',
        training_title: 'Bibliothèque d\'entraînement',
        training_subtitle: 'Trouvez des resources d\'entraînement personnalisées, 10-20 minutes par séance.',
      },
      all_complete: {
        title: 'Bibliothèque d\'entraînement',
        subtitle: 'Parcourez à nouveau des séances clefs et révisez les concepts',
        step_completed: {
          title: 'Révision de l\'apprentissage terminée!',
          congratulations: 'Bien joué! Continuez à utiliser les outils et les stratégies pour renforcer vos compétences.',
        },
      },
      recommended: {
        title: 'Entraînement de la santé mentale',
      },
    },
    connect_others: {
      community_forums: {
        title: 'Se connecter et échanger',
        description: 'Communauté',
        time: '~5 mins',
        title_completed: 'Connexion à la communauté terminée!',
        congratulations: 'Merci de faire partie de la communauté!',
      },
      strategic_breathing: {
        title: 'La respiration méthodique',
        subtitle: 'Prenez un moment pour respirer et vous relaxer avant d\'explorer Starling.',
        description: 'Vidéo',
        time: '~2 mins',
        title_completed: 'Exercice de respiration méthodique terminé!',
        congratulations: 'Bravo! Vous pouvez consulter cette stratégie à tout moment dans la section <strong>Vidéos</strong>.',
      },
      guided_imagery: {
        title: 'L\'imagerie guidée',
        subtitle: 'Ce court exercice vous aidera a vous détendre en imaginant une scène relaxante.',
        description: 'Vidéo',
        time: '~3 mins',
        title_completed: 'Exercice d\'imagerie guidée terminé!',
        congratulations: 'Bravo! Vous pouvez consulter cette stratégie à tout moment dans la section <strong>Vidéos</strong>.',
      },
      mindfulness: {
        title: 'Consciencia plena',
        subtitle: 'Attrapez une menthe, une noix ou un petit morceau de fruit et essayez cet exercice afin de concentrer votre attention et d\'être présent.',
        description: 'Vidéo',
        time: '~3 mins',
        title_completed: 'Exercice de pleine conscience terminé!',
        congratulations: 'Bravo! Vous pouvez consulter cette stratégie à tout moment dans la section <strong>Vidéos</strong>.',
      },
    },
    practice: {
      checkup_first: {
        title: 'Terminez un Bilan de Santé pour initialiser vos recommandations d\'<strong>Exercice</strong>.',
      },
      all_complete: {
        title: 'Exercices',
        subtitle: 'Parcourez à nouveau des exercices clefs.',
        step_completed: {
          title: 'Révision des exercices terminée!',
          congratulations: 'Revenez aux exercices à tout moment pour mettre en pratique vos compétences.',
        },
      },
    },
    buttons: {
      explore: 'Explorez',
      more: 'Plus',
      profile: 'Profil',
    },
  },
  memberIdModal: {
    title: 'Entrez votre identifiant de membre',
    description: 'vous demande d\'ajouter votre identifiant de membre dans votre profil pour vérifier que vous faites partie de l\'organisation. Vos informations et résultats resteront anonymes.',
    contact_text: 'Veillez contacter votre organisation pour localiser votre identifiant de membre.',
    button: 'Sauvegarder',
  },
  pickup_image: {
    normal: 'pickup_normal_fr.svg',
    active: 'pickup_active_fr.svg',
  },
  learn_about: {
    title: 'Découvrez Starling.',
  },
  next_step: {
    title: 'Prochaine étape pour vous',
    lets_go: 'Allons-y !',
  },
  user_stats_card: {
    subtitle: 'Vos résultats',
    widgets: {
      battery: {
        value: '{value}/10',
        text: 'Énergie',
        additional: 'Plus de 3 jours',
      },
      anxiety_meter: {
        text: 'Anxiété',
      },
      resilience_meter: {
        text: 'Résilience',
      },
      depression_meter: {
        text: 'Dépression',
      },
      checkup_reminder: {
        empty: 'Prochain bilan de santé recommandé dans <strong class="checkup-value">___</strong> jours',
        past: 'Votre prochain bilan de santé recommandé est <strong class="checkup-value">dû</strong>',
        future: 'Prochain bilan de santé recommandé dans <strong class="checkup-value">{count}</strong> jours',
      },
    },
    incentive: 'Vos résultats apparaîtront lorsque vous aurez réalisé votre premier bilan de santé.',
    buttons: {
      first_check_up: 'Faites votre premier bilan',
      check_up: 'Faites un bilan de santé',
      check_up_results: 'Visualisez vos progrès',
    },
    explanations: {
      title: 'Ce que vos chiffres signifient',
      global: 'Ces résultats sont ceux de votre dernier bilan de santé.',
      battery: '<strong>Batterie</strong> = le niveau d\'énergie rapporté ce jour-là.',
      anxiety_depression: '<strong>Pointages anxiété et dépression</strong> = une norme scientifique, allant de :',
      anxiety_depression_levels: [
        { interval: '0 - 2', name: 'En santé' },
        { interval: '3 - 4', name: 'Frontière' },
        { interval: '5 - 9', name: 'Léger' },
        { interval: '10 - 14', name: 'Modéré' },
        { interval: '> 14', name: 'Grave' },
      ],
      resilience: '<strong>Résilience</strong> = le degré de résilience rapporté ce jour-là, allant de :',
      resilience_levels: [
        { interval: '6 - 8', name: 'Grande' },
        { interval: '3 - 5', name: 'Modérée' },
        { interval: '0 - 2', name: 'Faible' },
      ],
      incentive: 'Suivez vos progrès en faisant un bilan de santé régulièrement.',
    },
  },
  training_courses: {
    title: 'Thérapies guidées',
  },
  community_card: {
    title: 'Forums de la Communauté Starling',
    subtitle: 'Un endroit sûr pour raconter vos expériences, apprendre des personnes qui vivent des situations similaires et trouver du soutien.',
  },
  video_library_card: {
    title: 'Vidéothèque',
    subtitle: 'Essayez une stratégie, apprenez-en davantage sur l\'autogestion de la santé mentale ou plongez dans la science qui sous-tend la TCC.',
  },
  referral_acceptance: {
    title: 'Mettez à jour vos informations',
    subtitle: 'Mettez à jour vos informations',
    subheader: {
      content: [
        'Nous sommes là pour vous aider à améliorer votre santé mentale et à vous prioriser vous-même durant votre congé. Découvrez vos habitudes, développez des stratégies et renforcez votre confiance en vous pour retourner au travail.',
        'Avant de commencer, confirmons certaines de vos informations.',
      ],
    },
    profile_title: 'Tout d\'abord, mettons à jour votre profil',
    cancel_button: 'Annuler',
    message: {
      title: 'On vous convie au programme Recouvrer la santé!',
      content: '<i>{caseManager}</i> pense que le programme Recouvrer la santé pourrait vous être utile :',
      cta: 'Commencez à l’explorer dès aujourd’hui!',
      buttons: {
        accept: 'Accepter l\'invitation',
        decline: 'Refuser l\'invitation',
      },
    },
    update_profile: {
      title: 'Mettez à jour vos informations',
      buttons: {
        accept: 'C\'est parti!',
        back: 'Retour',
      },
    },
  },
};
