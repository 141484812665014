/* eslint-disable security/detect-unsafe-regex */
const BADGE_REGEX = /(?:<badge(?:\.color:(.*?))?>)(.*?)(?:<\/badge>)/gi;

const ICON_REGEX = /##([^\s].*?)(\s|$)/gi;
const ICON_SIZES = [
  { value: null, weight: '1em', bottom: '3px' },
  { value: 'xs', weight: '0.5em', bottom: '3px' },
  { value: 'sm', weight: '0.75em', bottom: '3px' },
  { value: 'md', weight: '1.25em', bottom: '3px' },
  { value: 'lg', weight: '1.5em', bottom: '3px' },
  { value: 'xl', weight: '1.75em', bottom: '3px' },
];

export default {
  methods: {
    markup(text) {
      text = this.iconMarkup(text);
      text = this.badgeMarkup(text);
      return text;
    },
    iconMarkup(text) {
      if (!text || !text.match(ICON_REGEX)) return text;

      let part;

      while ((part = ICON_REGEX.exec(text)) !== null) {
        let name = '';
        let size = ICON_SIZES[0];
        let color = '';

        const subparts = part[1].split('.');

        for (let i = 0; i < subparts.length; i++) {
          const subpart = subparts[i];

          if (i === 0) {
            name = subpart;
          } else {
            const sizeProp = ICON_SIZES.find(s => s.value === subpart);
            if (sizeProp) {
              size = sizeProp;
            } else {
              color = subpart;
            }
          }
        }

        if (name.startsWith('_')) {
          text = text.replace(part[0],
            ` <svg aria-hidden="true" style="margin-bottom:-${size.bottom};color:${color};" width="${size.weight}" height="${size.weight}"><use xlink:href="#${name.slice(1)}"></use></svg> `);
        } else {
          text = text.replace(part[0],
            ` <i aria-hidden="true" style="font-size:${size.weight};padding-bottom:${size.bottom};color:${color};" class="v-icon material-icons">${name}</i> `);
        }
      // });
      }

      return text;
    },
    badgeMarkup(text) {
      if (!text || !text.match(BADGE_REGEX)) return text;

      let part;

      while ((part = BADGE_REGEX.exec(text)) !== null) {
        const color = part[1] || 'var(--StarlingPaleRed)';
        const name = (part[2] && part[2].trim()) ? `<span>${part[2]}</span>` : '';
        text = text.replace(part[0], `<div class="custom-badge" style="background-color:${color}">${name}</div>`);
      }

      return text;
    },
  },
};
