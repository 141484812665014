import store from '../store';
import { Auth } from 'aws-amplify';
import Vue from 'vue';
import { SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY } from '@/views/landing/setup_profile/SetupAccessCode';
import { REFERRAL_STORAGE_KEY } from '@/views/landing/setup_referral/SetupReferral';

const LandingView = resolve => {
  require.ensure([ '@/views/landing/Landing.vue' ], () => {
    resolve(require('@/views/landing/Landing.vue'));
  }, 'landing');
};
const SignInView = resolve => {
  require.ensure([ '@/views/landing/sign_in/SignIn.vue' ], () => {
    resolve(require('@/views/landing/sign_in/SignIn.vue'));
  }, 'signin');
};
const SignUpView = resolve => {
  require.ensure([ '@/views/landing/sign_up/SignUp.vue' ], () => {
    resolve(require('@/views/landing/sign_up/SignUp.vue'));
  }, 'signup');
};
const EmailVerificationStartView = resolve => {
  require.ensure([ '@/views/landing/email_verification/EmailVerificationStart.vue' ], () => {
    resolve(require('@/views/landing/email_verification/EmailVerificationStart.vue'));
  }, 'email_verification_start');
};
const EmailVerificationEndView = resolve => {
  require.ensure([ '@/views/landing/email_verification/EmailVerificationEnd.vue' ], () => {
    resolve(require('@/views/landing/email_verification/EmailVerificationEnd.vue'));
  }, 'email_verification_end');
};
const SetupProfileView = resolve => {
  require.ensure([ '@/views/landing/setup_profile/SetupProfile.vue' ], () => {
    resolve(require('@/views/landing/setup_profile/SetupProfile.vue'));
  }, 'setup_profile');
};
const SetupAccessCodeView = resolve => {
  require.ensure([ '@/views/landing/setup_profile/SetupAccessCode.vue' ], () => {
    resolve(require('@/views/landing/setup_profile/SetupAccessCode.vue'));
  }, 'setup_access_code');
};
const SetupReferralView = resolve => {
  require.ensure([ '@/views/landing/setup_referral/SetupReferral.vue' ], () => {
    resolve(require('@/views/landing/setup_referral/SetupReferral.vue'));
  }, 'setup_referral');
};
const FederatedEndView = resolve => {
  require.ensure([ '@/views/landing/federated/FederatedEnd.vue' ], () => {
    resolve(require('@/views/landing/federated/FederatedEnd.vue'));
  }, 'federated_end');
};
const FederatedSignoutView = resolve => {
  require.ensure([ '@/views/landing/federated/FederatedSignOut.vue' ], () => {
    resolve(require('@/views/landing/federated/FederatedSignOut.vue'));
  }, 'federated_signout');
};
const ForgottenPasswordView = resolve => {
  require.ensure([ '@/views/landing/reset_password/ForgottenPassword.vue' ], () => {
    resolve(require('@/views/landing/reset_password/ForgottenPassword.vue'));
  }, 'forgotten_password');
};
const ResetPasswordView = resolve => {
  require.ensure([ '@/views/landing/reset_password/ResetPassword.vue' ], () => {
    resolve(require('@/views/landing/reset_password/ResetPassword.vue'));
  }, 'reset_password');
};
const NewPasswordView = resolve => {
  require.ensure([ '@/views/landing/reset_password/NewPassword.vue' ], () => {
    resolve(require('@/views/landing/reset_password/NewPassword.vue'));
  }, 'new_password');
};
const PresentationView = resolve => {
  require.ensure([ '@/views/landing/Presentation.vue' ], () => {
    resolve(require('@/views/landing/Presentation.vue'));
  }, 'presentation');
};

const MaintenanceView = resolve => {
  require.ensure([ '@/views/landing/Maintenance.vue' ], () => {
    resolve(require('@/views/landing/Maintenance.vue'));
  }, 'maintenance');
};

export const SUBDOMAIN_SSO_REDIRECT = 'subdomain-sso-redirect';

export default {
  path: '/landing',
  component: LandingView,
  meta: { public: true },
  beforeEnter(to, from, next) {
    if (store.state.isAuthorized) {
      if (to.name === 'referral_register') {
        next();
        return;
      }
      if (to.name === 'maintenance') {
        next();
        return;
      }
      if (to.name === 'federated_end') {
        next();
        return;
      }
      if (to.name === 'setup_access_code') {
        next();
        return;
      }
      next({ name: 'home' });
    } else {
      next();
    }
  },
  children: [
    {
      path: '',
      name: 'presentation',
      component: PresentationView,
      meta: { public: true, footer: 'blue' },
    },
    {
      name: 'maintenance',
      path: '/maintenance',
      component: MaintenanceView,
      meta: { public: true, footer: 'simple' },
    },
    {
      name: 'self_register',
      path: 'self_register',
      redirect: { name: 'sign_up' },
    },
    {
      name: 'sign_in',
      path: 'sign_in',
      component: SignInView,
      meta: { public: true, footer: 'simple' },
    },
    {
      name: 'sign_in_otp',
      path: 'sign_in_otp',
      meta: { public: true, footer: 'simple' },
      beforeEnter(to, from, next) {
        const uuid = to.query['uuid'];
        const code = to.query['code'];
        const username = to.query['username'];
        Vue.$log.debug('Sign in OTP', uuid, code, username);

        store.dispatch('identity/signInOTP', {
          uuid,
          username,
          code,
          clientMetadata: {
            subdomain: store.state.identity.partner?.subdomain,
            locale: localStorage.getItem('currLang'),
          },
        }).then(res => {
          Vue.$log.error('Sign In OTP response', res);
          if (res.type === 'manual') {
            return store.dispatch('identity/loginManually', res)
              .then(loginResult => {
                if (loginResult.passwordChange) {
                  next({ name: 'new_password' });
                  return;
                }
                if (!loginResult || !loginResult.userIdentity || !loginResult.userIdentity.location || !loginResult.userIdentity.location['@type']) {
                  next({ name: 'sign_up' });
                  return;
                }
                if (loginResult.userIdentity.location['@type'] === 'nowhere') {
                  store.dispatch('identity/checkReferral', { email: loginResult.userIdentity.username }).then(refIdentity => {
                    if (refIdentity) {
                      next({
                        name: 'referral_register',
                        query: { referral_key: refIdentity.referralKey },
                      });
                    } else {
                      next({ name: 'setup_access_code' });
                    }
                  });
                } else if (loginResult.userIdentity.location['@type'] === 'database') {
                  if (!loginResult.redirect) {
                    next({ name: 'home' });
                  }
                }
              }, err => {
                Vue.$log.error('Manual login failed', err);
                next({ name: 'sign_up' });
              });
          } else if (res.type === 'error') {
            Vue.$log.error('Sign In OTP failed');
            next({ name: 'sign_up' });
          }
        }, err => {
          Vue.$log.error('Sign In OTP failed', err);
          next({ name: 'sign_up' });
        });
      },
    },
    {
      name: 'sign_up',
      path: 'sign_up',
      component: SignUpView,
      meta: { public: true, footer: 'simple' },
      beforeEnter(to, from, next) {
        if (to.query['ac_imprint']) {
          localStorage.setItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY, to.query['ac_imprint']);
          next({ name: 'sign_up', query: {} });
          return;
        }
        next();
      },
    },
    {
      name: 'setup_access_code',
      path: 'setup_access_code',
      component: SetupAccessCodeView,
      meta: { public: false, footer: 'simple' },
    },
    {
      name: 'referral_register',
      path: 'referral_register',
      component: SetupReferralView,
      meta: { public: true, footer: 'simple' },
      async beforeEnter(to, from, next) {
        const referralKey = to.query.referral_key;
        localStorage.setItem(REFERRAL_STORAGE_KEY, referralKey);
        next();
      },
    },
    {
      name: 'setup_profile',
      path: 'setup_profile',
      component: SetupProfileView,
      meta: { public: false, footer: 'simple' },
    },
    {
      name: 'federated_start',
      path: 'federated_start',
      meta: { public: true, footer: 'simple' },
      beforeEnter(to, from, next) {
        Vue.$log.debug('Federated provider', to, from, next);
        store.commit('setLoadingView', true);

        if (!to.query['provider']) {
          next({ name: 'home' });
          return;
        }
        if (store.state.identity.type !== 'BASIC') {
          const redirectPage = `${window.location.protocol}//${process.env.VUE_APP_COGNITO_COOKIE_DOMAIN}${to.fullPath}&subdomain=${store.state.identity.subdomain}`;
          window.location.href = redirectPage;
        } else {
          if (to.query.subdomain) {
            localStorage.setItem(SUBDOMAIN_SSO_REDIRECT, to.query.subdomain);
          }
          Auth.federatedSignIn({ provider: to.query['provider'] })
            .catch(err => {
              Vue.$log.debug('Cannot login with the federated provider', err);
              store.commit('setLoadingView', false);
              next({ name: 'home' });
            });
        }
      },
    },
    {
      name: 'federated_end',
      path: 'federated_end',
      component: FederatedEndView,
      meta: { public: true, footer: 'simple' },
      beforeEnter(to, from, next) {
        if (localStorage.getItem(SUBDOMAIN_SSO_REDIRECT)) {
          const redirectPage = `${window.location.protocol}//${localStorage.getItem(SUBDOMAIN_SSO_REDIRECT)}.${process.env.VUE_APP_COGNITO_COOKIE_DOMAIN}${to.fullPath}`;
          localStorage.removeItem(SUBDOMAIN_SSO_REDIRECT);
          window.location.href = redirectPage;
          return;
        }
        next();
      },
    },
    {
      name: 'federated_signout',
      path: 'federated_signout',
      component: FederatedSignoutView,
      meta: { public: true, footer: 'simple' },
    },
    {
      name: 'email_verification',
      path: 'email_verification',
      component: EmailVerificationStartView,
      meta: { public: true, footer: 'simple' },
    },
    {
      name: 'email_verification_end',
      path: 'email_verification_end',
      component: EmailVerificationEndView,
      meta: { public: true, footer: 'simple' },
    },
    {
      name: 'forgotten_password',
      path: 'forgotten_password',
      component: ForgottenPasswordView,
      meta: { public: true, footer: 'simple' },
    },
    {
      name: 'reset_password',
      path: 'reset_password',
      component: ResetPasswordView,
      meta: { public: true, footer: 'simple' },
    },
    {
      name: 'new_password',
      path: 'new_password',
      component: NewPasswordView,
      meta: { public: true, footer: 'simple' },
    },
  ],
};
