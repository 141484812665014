import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';
import routes from './routes';
import { SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY } from '@/views/landing/setup_profile/SetupAccessCode';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  Vue.$log.debug('Router to', to, 'from', from);
  if (to.query && to.query.locale) {
    localStorage.setItem('currLang', to.query.locale);
  }
  if (to.query && to.query['ac_imprint']) {
    localStorage.setItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY, to.query['ac_imprint']);
  }
  if (to.matched.length === 0 || (!to.matched.every(record => record.meta.public !== undefined && record.meta.public) && !store.state.isAuthorized)) {
    if (to.fullPath !== '/app') {
      store.commit('setAfterLoginRoute', to.fullPath);
    }
    const goToSignIn = to.matched?.length ? to.matched[to.matched.length - 1].props?.signIn : false;
    next({ name: goToSignIn ? 'sign_in' : 'presentation' });
    return;
  }
  if (store.state.afterLoginRoute && to.name === 'home') {
    const path = store.state.afterLoginRoute;
    store.commit('setAfterLoginRoute', undefined);
    next(path);
    return;
  }
  next();
});

router.afterEach((to) => {
  store.commit('setLoadingView', false);
});

export default router;
