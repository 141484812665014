var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "favorite-icon",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "12.5 12.5 35 35",
        fill: "none"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M21.1802 44.7026C20.4236 45.159 19.4902 44.4818 19.6893 43.621L21.6029 35.3485C21.8533 34.2659 21.4849 33.1336 20.6453 32.4057L14.225 26.8395C13.5575 26.2607 13.9134 25.1641 14.7936 25.0877L23.2862 24.3504C24.3938 24.2543 25.3573 23.5536 25.7901 22.5296L29.0789 14.7481C29.423 13.9339 30.577 13.9339 30.9211 14.7481L34.2099 22.5296C34.6427 23.5536 35.6062 24.2543 36.7138 24.3504L45.2064 25.0877C46.0866 25.1641 46.4425 26.2607 45.775 26.8395L39.3547 32.4057C38.5151 33.1336 38.1467 34.2659 38.3971 35.3485L40.3107 43.621C40.5098 44.4818 39.5764 45.159 38.8198 44.7026L31.5496 40.3169C30.5965 39.742 29.4035 39.742 28.4504 40.3169L21.1802 44.7026Z",
          "stroke-width": "2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }